export type Product = {
    id: number;
    name: string;
}

interface ProductContainer {
    [key: string]: Product;
}

export const Products: ProductContainer = {
    Honey: {
        id: 0,
        name: "Honey",
    },
    Thistle: {
        id: 1,
        name: "Thistle",
    },
    NeckTail: {
        id: 2,
        name: "Neck Tail",
    },
    Coal: {
        id: 3,
        name: "Coal",
    },
    Bloodbag: {
        id: 2,
        name: "Bloodbag",
    },
    GreydwarfEye: {
        id: 4,
        name: "Greydwarf Eye",
    },
    PoisonResistancePot: {
        id: 5,
        name: "Poison Resistance Mead",
    },
    Entrails: {
        id: 6,
        name: "Entrails",
    },
    BoarMeat: {
        id: 7,
        name: "Raw Boar Meat",
    },
    Sausage: {
        id: 8,
        name: "Sausage",
    },
    QueensJam: {
        id: 9,
        name: "Queens Jam",
    },
    Raspberries: {
        id: 10,
        name: "Raspberry",
    },
    Blueberries: {
        id: 11,
        name: "Blueberry",
    },
    Barley: {
        id: 12,
        name: "Barley",
    },
    Cloudberries: {
        id: 13,
        name: "Cloudberry",
    },
    Dandelion: {
        id: 14,
        name: "Dandelion",
    },
    YellowMushroom: {
        id: 15,
        name: "Yellow Mushroom",
    },
    BarleyFlour: {
        id: 16,
        name: "Barley Flour",
    },
    LoxMeat: {
        id: 17,
        name: "Lox Meat",
    },
    SerpentMeat: {
        id: 18,
        name: "Serpent Meat",
    },
    RedMushroom: {
        id: 19,
        name: "Red Mushroom",
    },
    Onion: {
        id: 20,
        name: "Onion",
    },
    BoarJerky: {
        id: 21,
        name: "Boar Jerky",
    },
    Carrot: {
        id: 22,
        name: "Carrot",
    },
    CarrotSoup: {
        id: 23,
        name: "Carrot Soup",
    },
    DeerStew: {
        id: 24,
        name: "Deer Stew",
    },
    CookedDeerMeat: {
        id: 25,
        name: "Cooked Deer Meat",
    },
    MincedMeatSauce: {
        id: 26,
        name: "Minced Meat Sauce",
    },
    Ooze: {
        id: 27,
        name: "Ooze",
    },
    Muckshake: {
        id: 28,
        name: "Muckshake",
    },
    TurnipStew: {
        id: 29,
        name: "Turnip Stew",
    },
    Turnip: {
        id: 30,
        name: "Turnip",
    },
    BlackSoup: {
        id: 31,
        name: "Black Soup",
    },
    CookedSerpent: {
        id: 32,
        name: "Cooked Serpent Meat",
    },
    SerpentStew: {
        id: 34,
        name: "Serpent Stew",
    },
    OnionSoup: {
        id: 35,
        name: "Onion Soup",
    },
    WolfMeat: {
        id: 36,
        name: "Wolf Meat",
    },
    WolfJerky: {
        id: 37,
        name: "Wolf Jerky",
    },
    WolfSkewer: {
        id: 38,
        name: "Wolf Skewer",
    },
    Eyescream: {
        id: 39,
        name: "Eyescream",
    },
    FreezeGland: {
        id: 40,
        name: "Freeze Gland",
    },    
    CookedFish: {
        id: 41,
        name: "Cooked Fish",
    },
    FishWrap: {
        id: 42,
        name: "Fish Wrap",
    },
    BreadDough: {
        id: 43,
        name: "Bread Dough",
    },
    FrostReistancePot: {
        id: 44,
        name: "Frost Resistance Mead"
    },
    FireReistancePot: {
        id: 45,
        name: "Fire Resistance Mead"
    },
    MediumHealingPot: {
        id: 46,
        name: "Medium Healing Mead"
    },
    MediumStaminaPot: {
        id: 47,
        name: "Medium Stamina Mead"
    },
    MinorStaminaPot: {
        id: 48,
        name: "Minor Stamina Mead"
    },
    MinorHealthPot: {
        id: 49,
        name: "Minor Health Mead"
    },
    TastyMead: {
        id: 50,
        name: "Tasty Mead"
    },
    LoxMeatPie: {
        id: 51,
        name: "Lox Meat Pie"
    },
    BloodPudding: {
        id: 52,
        name: "Blood Pudding"
    }
}
