import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import RecipeCalculator from './RecipeCalculator';

function App() {
  document.title = "Shopheim: the valheim chef's calculator"

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={1800}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        transition={Slide}
        limit={3}
        closeButton={false}
        />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Caesar+Dressing"></link>
      <h1 style={{textAlign: 'center', fontSize: '3em', fontFamily:'Caesar Dressing'}}>Shopheim</h1>
      <h5 style={{textAlign: 'center'}}>the valheim chef's calculator</h5>
      <RecipeCalculator />
    </div>
  );
}

export default App;
