import React, { useState } from "react";
import { Recipe } from '../../Data/Recipes';
import IngredientList from "./IngredientList";
import { getQuantity, updateQuantity } from '../../Data/StateStore'

type recipeCardProps = {
    recipe: Recipe,
    addItem: Function
}

const RecipeCard = function(props: recipeCardProps) {
    const getMinimumAmount = (quantityNeeded: number, batchQuantity: number) => {
        return Math.ceil(quantityNeeded / batchQuantity) * batchQuantity;
    }

    const [quantity, setQuantity] = useState<number>(getQuantity(props.recipe.id).quantity);
    const updateInputQuantity = (value: string) => {
        const rawValue = parseInt(value);
        const numValue = (!rawValue || isNaN(rawValue) ? 0 : rawValue);
        setQuantity(numValue);
        updateQuantity({ingredientid: props.recipe.id, quantity: numValue});
    }

    return <div className={"col-xs-6 col-md-4 col-lg-3"} key={props.recipe.id}>
                <div className={"box"} style={{paddingBottom: '20px'}}>
                    <h4>{props.recipe.name}</h4>
                    <div style={{fontSize: 14}}>
                        {props.recipe.result.quantity} per batch
                    </div>
                    <input
                        className={"quantity-input"}
                        style={{maxWidth: '120px'}}
                        inputMode="numeric"
                        value={quantity}
                        onChange={(e) => updateInputQuantity(e.target.value)}
                        onFocus={e => e.target.select()}>
                    </input>
                    <br />
                    <button
                        onClick={() => props.addItem(getMinimumAmount(quantity, props.recipe.result.quantity), props.recipe)}>
                        Add {getMinimumAmount(quantity, props.recipe.result.quantity)} to Cart
                    </button>
                    <div style={{fontSize: 12}}>
                        <IngredientList recipe={props.recipe} quantityNeeded={quantity}  />
                    </div>
                </div>
            </div>
}

export default RecipeCard;