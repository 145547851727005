import React, { useEffect, useState } from 'react';
import { Recipe } from '../Data/Recipes';

type Item = {
    quantity: number; 
    recipe: Recipe;
}

const getRequiredAmount = (item: Item) => {
    return Math.ceil(item.quantity / item.recipe.result.quantity);
}

function ItemDisplay(props: {item: Item, removeItem: Function}) {
    const { recipe } = props.item;

    return(
        <div>
            <div><button type="button" className="remove-button" onClick={() => props.removeItem(recipe)}>x</button> {getRequiredAmount(props.item) * recipe.result.quantity} {recipe.name}</div>
        </div>
    );
}

function IngredientsRequired(props: {items: Item[]}) {

    const [itemCalcs, setItemCalcs] = useState<Record<string, number>>({});
    
     useEffect(() => {
        let ingredients: Record<string, number> = {};
        props.items.forEach(item => {
            item.recipe.ingredients.forEach(ing => {
                if (!ingredients[ing.product.name] || ingredients[ing.product.name] === 0) { 
                    ingredients[ing.product.name] = ing.quantity * getRequiredAmount(item); 
                }
                else {
                    ingredients[ing.product.name] = ingredients[ing.product.name] + (ing.quantity * getRequiredAmount(item));  
                }
            })
        });
        
        setItemCalcs(ingredients);
    }, [props.items])

    return (
        <>
        <div>
            {Object.keys(itemCalcs).map(key => {
                return( 
                    <div key={key}>
                        {itemCalcs[key]} {key}
                    </div>
                )
            })}
        </div>
        </>
    );
}

type cartProps = {
    selectedRecipes: Array<Item>,
    removeItemFromCart: Function
}

function Cart(props: cartProps) {
    return (
    <div className={"box"}>
        <h4 className={"hidden-xs"}>Your Cart</h4>
        <hr />
        <u>To Make:</u>
        {props.selectedRecipes.length !== 0 ? 
            props.selectedRecipes.sort((a, b) => { return a.recipe.name < b.recipe.name ? -1 : 1 }).map(r => {
                return(
                    <div key={r.recipe.id}>
                        <ItemDisplay item={r} removeItem={props.removeItemFromCart} />
                    </div>
                );
            })
        : 
            <div>(Empty Cart)</div> 
        }
        <br />
        <div className="ingredient-container">
            <div className="ingredient-header">
                <u>Ingredients Required: </u>
            </div>
            <IngredientsRequired items={props.selectedRecipes} />
        </div>
    </div>
    );
}

export default Cart;