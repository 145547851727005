export type StateStore = {
    ingredientid: number,
    quantity: number
}

const storage = [] as Array<StateStore>
export const updateQuantity = (state: StateStore) => {
    const existingState = storage.map(e => { return e.ingredientid }).indexOf(state.ingredientid)
    if (existingState !== -1) {
        storage.splice(existingState)
    }
    storage.push(state);
}

export const getQuantity = (ingredientid: number) => {
    const currentStateStore = storage.filter(s=>s.ingredientid === ingredientid)[0];
    return currentStateStore ? currentStateStore : { ingredientid: 0, quantity: 1 }
}

export default updateQuantity