import React from "react";
import { Recipe } from "../../Data/Recipes";

function IngredientsList(props: {recipe: Recipe, quantityNeeded: number}) {
    return (
        <div>
            {props.recipe.ingredients.map((i: any) => { return (
                <div key={i.product.name}>
                  {Math.ceil(props.quantityNeeded / props.recipe.result.quantity) * i.quantity} {i.product.name}
                </div>
            );})}
        </div>
    );
}

export default IngredientsList