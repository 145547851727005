import { Product, Products } from './Products';

export type Recipe = {
    id: number;
    name: string;
    category: RecipeCategories;
    ingredients: Array<{ quantity: number, product: Product }>;
    result: { quantity: number, product: Product };
}

export enum RecipeCategories {
    Food = "Food",
    Mead = "Mead",
    //Other = "Other",
}

export const RecipeList:Array<Recipe> = [
    {
        id: 0,
        name: "Poison Resistance",
        category: RecipeCategories.Mead,
        ingredients: [ 
            { quantity: 10, product: Products.Honey },
            { quantity: 5, product: Products.Thistle },
            { quantity: 1, product: Products.NeckTail },
            { quantity: 10, product: Products.Coal }
         ],
        result: { quantity: 6, product: Products.PoisonResistancePot }
    },
    {
        id: 1,
        name: "Frost Resistance",
        category: RecipeCategories.Mead,
        ingredients: [ 
            { quantity: 10, product: Products.Honey },
            { quantity: 5, product: Products.Thistle },
            { quantity: 2, product: Products.Bloodbag },
            { quantity: 1, product: Products.GreydwarfEye }
         ],
        result: { quantity: 6, product: Products.FrostReistancePot }
    },
    {
        id: 2,
        name: "Sausage",
        category: RecipeCategories.Food,
        ingredients: [ 
            { quantity: 4, product: Products.Entrails },
            { quantity: 1, product: Products.BoarMeat },
            { quantity: 1, product: Products.Thistle }
         ],
        result: { quantity: 4, product: Products.Sausage }
    },
    {
        id: 3,
        name: "Queens Jam",
        category: RecipeCategories.Food,
        ingredients: [ 
            { quantity: 8, product: Products.Raspberries },
            { quantity: 6, product: Products.Blueberries }
         ],
        result: { quantity: 4, product: Products.QueensJam }
    },
    {
        id: 4,
        name: "Fire Resistance",
        category: RecipeCategories.Mead,
        ingredients: [ 
            { quantity: 10, product: Products.Barley },
            { quantity: 10, product: Products.Cloudberries }
         ],
        result: { quantity: 6, product: Products.FireReistancePot }
    },
    {
        id: 5,
        name: "Medium Healing",
        category: RecipeCategories.Mead,
        ingredients: [ 
            { quantity: 10, product: Products.Honey },
            { quantity: 4, product: Products.Bloodbag },
            { quantity: 10, product: Products.Raspberries },
            { quantity: 1, product: Products.Dandelion }
         ],
        result: { quantity: 6, product: Products.MediumHealingPot }
    },
    {
        id: 6,
        name: "Medium Stamina",
        category: RecipeCategories.Mead,
        ingredients: [
            { quantity: 10, product: Products.Honey },
            { quantity: 10, product: Products.Cloudberries },
            { quantity: 10, product: Products.YellowMushroom }
         ],
        result: { quantity: 6, product: Products.MediumStaminaPot }
    },
    {
        id: 7,
        name: "Minor Healing",
        category: RecipeCategories.Mead,
        ingredients: [
            { quantity: 10, product: Products.Honey },
            { quantity: 5, product: Products.Blueberries },
            { quantity: 10, product: Products.Raspberries },
            { quantity: 1, product: Products.Dandelion }
         ],
        result: { quantity: 6, product: Products.MinorHealthPot }
    },
    {
        id: 8,
        name: "Minor Stamina",
        category: RecipeCategories.Mead,
        ingredients: [
            { quantity: 10, product: Products.Honey },
            { quantity: 10, product: Products.Raspberries },
            { quantity: 10, product: Products.YellowMushroom }
         ],
        result: { quantity: 6, product: Products.MinorStaminaPot }
    },
    {
        id: 9,
        name: "Tasty Mead",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 10, product: Products.Honey },
            { quantity: 10, product: Products.Raspberries },
            { quantity: 5, product: Products.Blueberries }
         ],
        result: { quantity: 6, product: Products.TastyMead }
    },
    {
        id: 10,
        name: "Lox Meat Pie",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 4, product: Products.BarleyFlour },
            { quantity: 2, product: Products.Cloudberries },
            { quantity: 2, product: Products.LoxMeat }
         ],
        result: { quantity: 1, product: Products.LoxMeatPie }
    },
    {
        id: 12,
        name: "Blood Pudding",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 2, product: Products.Thistle },
            { quantity: 2, product: Products.Bloodbag },
            { quantity: 4, product: Products.BarleyFlour }
         ],
        result: { quantity: 1, product: Products.BloodPudding }
    },
    {
        id: 13,
        name: "Boar Jerky",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 2, product: Products.BoarMeat },
            { quantity: 2, product: Products.Honey }
         ],
        result: { quantity: 2, product: Products.BoarJerky }
    },
    {
        id: 14,
        name: "Carrot Soup",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.RedMushroom },
            { quantity: 3, product: Products.Carrot }
         ],
        result: { quantity: 1, product: Products.CarrotSoup }
    },
    {
        id: 15,
        name: "Deer Stew",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.Blueberries },
            { quantity: 1, product: Products.Carrot },
            { quantity: 1, product: Products.CookedDeerMeat }
         ],
        result: { quantity: 1, product: Products.DeerStew }
    },
    {
        id: 16,
        name: "Minced Meat Sauce",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.BoarMeat },
            { quantity: 1, product: Products.NeckTail },
            { quantity: 1, product: Products.Carrot }
         ],
        result: { quantity: 1, product: Products.MincedMeatSauce }
    },
    {
        id: 17,
        name: "Muckshake",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.Ooze },
            { quantity: 2, product: Products.Raspberries },
            { quantity: 2, product: Products.Blueberries }
         ],
        result: { quantity: 1, product: Products.Muckshake }
    },
    {
        id: 18,
        name: "Turnip Stew",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.BoarMeat },
            { quantity: 3, product: Products.Turnip }
         ],
        result: { quantity: 1, product: Products.TurnipStew }
    },
    {
        id: 19,
        name: "Black Soup",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.Bloodbag },
            { quantity: 1, product: Products.Honey },
            { quantity: 1, product: Products.Turnip },
         ],
        result: { quantity: 1, product: Products.BlackSoup }
    },
    {
        id: 20,
        name: "Serpent Stew",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.RedMushroom },
            { quantity: 2, product: Products.Honey },
            { quantity: 1, product: Products.CookedSerpent },
         ],
        result: { quantity: 1, product: Products.SerpentStew }
    },
    {
        id: 21,
        name: "Onion Soup",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 3, product: Products.Onion },
         ],
        result: { quantity: 1, product: Products.OnionSoup }
    },
    {
        id: 22,
        name: "Wolf Jerky",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.WolfMeat },
            { quantity: 1, product: Products.Honey },
         ],
        result: { quantity: 2, product: Products.WolfJerky }
    },
    {
        id: 23,
        name: "Wolf Skewer",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 1, product: Products.WolfMeat },
            { quantity: 1, product: Products.Onion },
            { quantity: 2, product: Products.RedMushroom },
         ],
        result: { quantity: 1, product: Products.WolfSkewer }
    },
    {
        id: 24,
        name: "Eyescream",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 3, product: Products.GreydwarfEye },
            { quantity: 1, product: Products.FreezeGland },
         ],
        result: { quantity: 1, product: Products.Eyescream }
    },
    {
        id: 25,
        name: "Fish Wrap",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 2, product: Products.CookedFish },
            { quantity: 4, product: Products.BarleyFlour },
         ],
        result: { quantity: 1, product: Products.FishWrap }
    },
    {
        id: 26,
        name: "Bread",
        category: RecipeCategories.Food,
        ingredients: [
            { quantity: 10, product: Products.BarleyFlour }
         ],
        result: { quantity: 2, product: Products.BreadDough }
    }
].sort((a, b) => { return a.name < b.name ? -1 : 1});

const PotionList:Array<Product> = [
    Products.PoisonResistancePot
]

export default PotionList;