import React, { ChangeEvent, useEffect, useState } from 'react';
import { Cart, RecipeCard } from './Pages';
import { RecipeList, Recipe, RecipeCategories } from './Data/Recipes';
import { toast } from 'react-toastify';

type Item = {
    quantity: number; 
    recipe: Recipe;
}
type FilterOptions = {
    category?: RecipeCategories,
    text?: string
}

function RecipeCalculator() {
    const [availableRecipes, setAvailableRecipes] = useState<Recipe[]>(RecipeList);
    const [selectedRecipes, setSelectedRecipes] = useState<Array<Item>>([]);
    const [availableRecipeFilter, setAvailableRecipeFilter] = useState<FilterOptions>();
    const [displayCart, setDisplayCart] = useState<Boolean>(false);
    const [filterName, setFilterName] = useState<string>("");
    const [filterCategory, setfilterCategory] = useState<string>("");

    const addOrUpdateItemInCart = (quantity: number, recipe: Recipe) => {
        const currentRecipe = selectedRecipes.filter(r => r.recipe.id === recipe.id);

        toast(`Added ${quantity} ${recipe.name} to Cart`,{
            position: toast.POSITION.TOP_CENTER
        });

        if (currentRecipe.length > 0) {
            currentRecipe[0].quantity += quantity;
            setSelectedRecipes(selectedRecipes.map(r => 
                r.recipe.id !== currentRecipe[0].recipe.id ? r : currentRecipe[0] ));
        } else {
            setSelectedRecipes([...selectedRecipes, { recipe: recipe, quantity: quantity }]);
        }
    }

    const removeItemInCart = (recipe: Recipe) => {
        setSelectedRecipes(selectedRecipes.filter(r => r.recipe.id !== recipe.id));
    }

    const filterRecipes = (filterCat?: ChangeEvent<HTMLSelectElement>, filterText?: ChangeEvent<HTMLInputElement>) => {
        if (filterCat) {
            const targetCategory = filterCat.target.value;

            setfilterCategory(targetCategory);
            if (targetCategory !== "") {
                const category = RecipeCategories[targetCategory as keyof typeof RecipeCategories];
                setAvailableRecipeFilter((current) => ({ ...current, category: category }));
            } else {
                setAvailableRecipeFilter((current) => ({ ...current, category: undefined }));
            }
        }
        if (filterText) {
            setFilterName(filterText.target.value);
            setAvailableRecipeFilter((current) => ({ ...current, text: filterText.target.value }));
        }
    }

    const countTotalQuantities = (items: Array<Item>) => {
        let count = 0;
        count = items.reduce((previous, current) => {return previous + current.quantity}, 0);
        return count;
    }

    useEffect(() => {
        let filteredRecipes = RecipeList;
        if (availableRecipeFilter !== undefined) {
            if (availableRecipeFilter.category) {
                filteredRecipes = filteredRecipes.filter(x => x.category === availableRecipeFilter.category);
            }

            filteredRecipes = filteredRecipes.filter(x => x.name.toLowerCase().includes(availableRecipeFilter.text ? availableRecipeFilter.text.toLowerCase() : ""));
        }

        setAvailableRecipes(filteredRecipes);
    }, [availableRecipeFilter])

    useEffect(() => {
        // Todo - Call a database...
        const savedSelectedRecipes = localStorage.getItem('shopheim.savedSelectedRecipes');
        if (savedSelectedRecipes) setSelectedRecipes(JSON.parse(savedSelectedRecipes));
    }, [])

    useEffect(() => {
        localStorage.setItem('shopheim.savedSelectedRecipes', JSON.stringify(selectedRecipes));
    }, [selectedRecipes])

    return (
        <div className={"container"}>
            <div className={"row hidden-sm hidden-md hidden-lg mobile-bar"} style={{position: 'sticky', top: '0px', padding: '10px', minHeight: '40px', textAlign: 'center'}}>
                <div className={"col-xs-12 hidden-sm hidden-md hidden-lg"}>
                    <span className={displayCart ? 'nav-inactive' : 'nav-active'} onClick={() => {setDisplayCart(false)}}>Available Recipes</span>
                    <span className={displayCart ? 'nav-active' : 'nav-inactive'} onClick={() => {setDisplayCart(true)}}>Cart ({countTotalQuantities(selectedRecipes)})</span>
                </div>
            </div>
            <div className={"row"}>
                {
                !displayCart ? 
                <div className={"col-xs-12 col-sm-9"}>
                    <div className={"box"}>
                        <h4 className={"hidden-xs"}>Available Recipes</h4>
                        <hr />
                        <div>
                            <div>
                                <span> Name: </span>
                                <input value={filterName} style={{maxWidth: '130px'}} onChange={(e) => filterRecipes(undefined, e)}></input> 
                                <span> Category: </span>
                                <select value={filterCategory} onChange={(e) => filterRecipes(e, undefined)}>
                                    <option value="">All</option>
                                    {Object.keys(RecipeCategories).map(val => {
                                        return <option key={val} value={val}>{val}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <hr />
                        <div className={"row"}>
                            { availableRecipes.map(recipe => <RecipeCard key={recipe.id} recipe={recipe} addItem={addOrUpdateItemInCart} />) }
                        </div>
                    </div>
                </div>
                :
                <div className={"col-xs-12"}>
                    <div className={"box"}>
                        <Cart selectedRecipes={selectedRecipes} removeItemFromCart={removeItemInCart} />
                    </div>
                </div>
                }
                <div className={"col-sm-3 hidden-xs"}>
                    <Cart selectedRecipes={selectedRecipes} removeItemFromCart={removeItemInCart} />
                </div>
            </div>
        </div>
    );
}

export default RecipeCalculator;